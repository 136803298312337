//NOTE - Library Import
import './App.css';
import React, {Suspense, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import ReactGA from "react-ga4";


//NOTE - App Components
import Header from './includes/header';
import Home from './home/home';
import About from './about/about';
import Portfolio from './portfolio/portfolio';
import Pricing from './pricing/pricing';
import Services from './services/services';
import ContactForm from './includes/contact';
import Footer from './includes/footer';
import TermsAndPrivacyPolicy from './terms/terms-and-privacy-policy';
import ErrorPage from './404';
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollToTop from './includes/scrollToTop';


const App = () => {
  const { setPathPageView, setIdentity } = useTrackingCode();
  setPathPageView(window.location.pathname);

  setIdentity("august@partneredmg.com");

  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
 
    return (
      <ParallaxProvider>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
          <ScrollToTop />
          <div className="App scroll-smooth">
            <Header />
            <div className="bg-grid-lines-mobile md:bg-grid-lines-webp bg-cover">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/services" element={<Services />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/terms-and-privacy-policy" element={<TermsAndPrivacyPolicy />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
              <ContactForm />
            </div>
            <Footer />
          </div>
          </Suspense>
        </Router>
      </ParallaxProvider>
    );
}

export default App;