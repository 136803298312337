import React from "react";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import {ReactComponent as FractionalPMIcon} from "./images/fractionalPM.svg";
import {ReactComponent as ProcessAuditsIcon} from "./images/ProcessAudits-icon.svg";
import {ReactComponent as UXIcon} from "./images/UXUIDesign-icon.svg";
import {ReactComponent as WebDevIcon} from "./images/WebDev-icon.svg";
import {ReactComponent as Arrow} from "./images/arrow-drop-down.svg"

const Services = () => {
    const { setPathPageView, setTrackPageView } = useTrackingCode();
    setPathPageView("/services");
    setTrackPageView();
    return (
        <div className="w-full flex flex-col px-6 py-12 container mx-auto">
            <div className="text-blue text-left font-bold pt-24 pb-32">
                <h1 className="text-5xl pointer-events-none">What We Can Do For You</h1>
                <p className="text-lg leading-8 mt-10 pointer-events-none">We work closely with our clients to ensure that our services are aligned with their business objectives, and strive to deliver the highest level of quality and professionalism in everything we do. Whatever your business needs may be, we are committed to providing the best possible service and helping you achieve your goals.</p>
            </div>
            <div className="flex flex-col text-white gap-9 justify-center align-center lg:flex-row">
                <div className="bg-blue px-5 py-6 rounded-3xl flex flex-col justify-start relative align-center gap-6 service-card h-52 hover:h-80 lg:w-1/4 lg:h-60 transition-all duration-300">
                    <FractionalPMIcon className="mx-auto"/>
                    <h2 className="text-2xl xl:text-3xl pointer-events-none">Fractional Project Managment</h2>
                    <Arrow className="mx-auto toggle-arrow absolute bottom-4 left-0 w-full flex flex-row justify-center items-center"/>
                    <p className="hidden text-sm xl:text-base leading-8 text-left hidden-text pointer-events-none">Our expert project managers provide cost-effective and efficient project management services to help you achieve your project goals.</p>
                </div>
                <div className="bg-blue px-5 py-6 rounded-3xl flex flex-col justify-start relative align-center gap-6 service-card h-52 hover:h-80 lg:w-1/4 lg:h-60 transition-all duration-300">
                    <ProcessAuditsIcon className="mx-auto"/>
                    <h2 className="text-2xl xl:text-3xl pointer-events-none">PMO Services</h2>
                    <Arrow className="mx-auto toggle-arrow absolute bottom-4 left-0 w-full flex flex-row justify-center items-center"/>
                    <p className="hidden text-sm xl:text-base leading-8 text-left hidden-text pointer-events-none">We help businesses improve their efficiency and productivity by analyzing and optimizing their business processes to achieve better results.</p>
                </div>
                <div className="bg-blue px-5 py-6 rounded-3xl flex flex-col justify-start relative align-center gap-6 service-card h-52 hover:h-80 lg:w-1/4 lg:h-60 transition-all duration-300">
                    <UXIcon className="mx-auto"/>
                    <h2 className="text-2xl xl:text-3xl pointer-events-none">Project Management Assessment</h2>
                    <Arrow className="mx-auto toggle-arrow absolute bottom-4 left-0 w-full flex flex-row justify-center items-center"/>
                    <p className="hidden text-sm xl:text-base leading-8 text-left hidden-text pointer-events-none">At PMG, our Project Management Assessment dives deep into your organization's current operational workflows to identify strengths and areas for improvement. Our expert team analyzes your processes, designs a tailored future-state strategy, and provides a comprehensive implementation plan to optimize efficiency.</p>
                </div>
                <div className="bg-blue px-5 py-6 rounded-3xl flex flex-col justify-start relative align-center gap-6 service-card h-52 hover:h-80 lg:w-1/4 lg:h-60 transition-all duration-300">
                    <WebDevIcon className="mx-auto stroke-svg"/>
                    <h2 className="text-2xl xl:text-3xl pointer-events-none">Automation Workflow Development</h2>
                    <Arrow className="mx-auto toggle-arrow absolute bottom-4 left-0 w-full flex flex-row justify-center items-center"/>
                    <p className="hidden text-sm xl:text-base leading-8 text-left hidden-text pointer-events-none">PMG's Automation Workflow Development service is designed to enhance operational efficiency and boost project management productivity. Our team crafts tailored automation solutions that streamline repetitive tasks, optimize workflows, and integrate seamlessly with your existing tools. By reducing manual effort and increasing precision, we help your organization save time, improve accuracy, and focus on high-impact activities that drive results.</p>
                </div>
            </div>
        </div>
    );
}

export default Services;